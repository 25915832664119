'use strict';

var mobileTouchLinkDelay = function() {
    var linkTouchStart = function(e) {
        var link = e.target.closest('.custom-link');
        
        e.preventDefault();
        e.stopPropagation();

        setTimeout(() => {
            window.location.href = link.href;
        }, 600);
    }

    var links = document.querySelectorAll('.image-animate .custom-link:not([mobileTouchHover])');
	links.forEach(function(link) {
		link.addEventListener('touchstart', linkTouchStart, { passive: false });
	});

    var removeEventListeners = function() {
        document.removeEventListener('touchstart', (e) => linkTouchStart(e), { passive: false });
    };

    return removeEventListeners;
}

var mobileTouchLinkHandlingWithHoverEffect = function() {

    var resetHoverState = function() {
        var hoverLinks = document.querySelectorAll('.mobileTouchHover');
        hoverLinks.forEach(function(link) {
            link.dataset.hoverShown = 'false';
        });
    };

    var onTouchStart = function(e) {
        // if (e.target.classList.contains('mobileTouchHover')) {
            if (this.dataset.hoverShown === 'true') {
                this.dataset.hoverShown = 'false';
                // window.location = e.target.href;
                return;
            }

            e.preventDefault();
            e.stopPropagation();
            this.dataset.hoverShown = 'true';
        // }
    }
	
    window.addEventListener('pageshow', resetHoverState);
    window.addEventListener('load', resetHoverState);
    window.addEventListener('popstate', resetHoverState);
    document.addEventListener('DOMContentLoaded', resetHoverState);

    // document.addEventListener('touchstart', (e) => onTouchStart(e), { passive: false });
	var hoverLinks = document.querySelectorAll('.mobileTouchHover');
	hoverLinks.forEach(function(link) {
		link.addEventListener('touchstart', onTouchStart, { passive: false });
	});

    var removeEventListeners = function() {
        window.removeEventListener('pageshow', onPageshow);
        window.removeEventListener('load', onLoad);
        window.removeEventListener('popstate', onPopstate);
        document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
        document.removeEventListener('touchstart', (e) => onTouchStart(e), { passive: false });
    };

    return removeEventListeners;

};

var init = function() {
    
	mobileTouchLinkHandlingWithHoverEffect();
    mobileTouchLinkDelay();

};

module.exports = {
	init: init
};
